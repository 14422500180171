exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-default-js": () => import("./../../../src/pages/default.js" /* webpackChunkName: "component---src-pages-default-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-news-[link]-js": () => import("./../../../src/pages/news/[link].js" /* webpackChunkName: "component---src-pages-news-[link]-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-redakcja-js": () => import("./../../../src/pages/redakcja.js" /* webpackChunkName: "component---src-pages-redakcja-js" */),
  "component---src-pages-wirtualnie-js": () => import("./../../../src/pages/wirtualnie.js" /* webpackChunkName: "component---src-pages-wirtualnie-js" */),
  "component---src-pages-zawody-fis-cup-[year]-js": () => import("./../../../src/pages/zawody/fis-cup/[year].js" /* webpackChunkName: "component---src-pages-zawody-fis-cup-[year]-js" */),
  "component---src-pages-zawody-puchar-kontynentalny-[year]-js": () => import("./../../../src/pages/zawody/puchar-kontynentalny/[year].js" /* webpackChunkName: "component---src-pages-zawody-puchar-kontynentalny-[year]-js" */),
  "component---src-pages-zawody-puchar-swiata-[year]-js": () => import("./../../../src/pages/zawody/puchar-swiata/[year].js" /* webpackChunkName: "component---src-pages-zawody-puchar-swiata-[year]-js" */)
}

